<script>
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";

export default {
  name: "PaymentAmountType",
  mixins: [GlobalMixin],
  props: {
    paymentAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    showAmount: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ['payment-updated'],
  data() {
    return {
      amount: 0,
      paymentType: '', // default payment type
      paidAmountInput: 0, // amount entered when "Other" is selected
      paidAmountFull: '',
      paidAmountQuarter: '',
      paidAmountHalf: '',
      error: '', // error handling for payment amount validation
    };
  },
  watch: {
    paidAmountInput() {
      this.validatePaidAmount()
      this.emitPaymentUpdate();
    },
    paymentAmount: {
      handler(newValue) {
        this.resetData();
        this.amount = newValue;
        this.initialLoad();
      },
      immediate: true,
    }
  },
  methods: {
    validatePaidAmount() {
      if (this.paidAmountInput === 0 || this.paidAmountInput === '' || isNaN(this.paidAmountInput)) {
        this.error = 'Amount cannot be zero or empty';
      } else if (this.paidAmountInput * 100 > this.amount) {
        this.error = 'Amount cannot exceed the total amount';
      } else {
        this.error = '';
      }
    },
    async resetData() {
      this.amount = 0;
      this.paymentType = '';
      this.paidAmountInput = 0;
      this.error = '';
      this.paidAmountFull = '';
      this.paidAmountQuarter = '';
      this.paidAmountHalf = '';
    },
    emitPaymentUpdate() {
      this.calculateAmounts();
    },

    calculateAmounts() {
      this.error = '';
      let paidAmount = 0;
      if (this.paymentType === 'quarter') {
        paidAmount = this.amount / 4;
        this.paidAmountQuarter = parseInt(paidAmount);
      } else if (this.paymentType === 'half') {
        paidAmount = this.amount / 2;
        this.paidAmountHalf = parseInt(paidAmount)
      } else if (this.paymentType === 'full') {
        paidAmount = this.amount;
        this.paidAmountFull = parseInt(paidAmount)
      } else if (this.paymentType === 'custom') {
        this.validatePaidAmount();
        paidAmount = parseFloat(this.paidAmountInput * 100)
      }
      const paidAmountInt = parseInt(paidAmount)
      const restAmount = this.amount - paidAmountInt;
      const result = {
        type: this.paymentType,
        paid_amount: paidAmountInt,
        rest_amount: restAmount >= 0 ? restAmount : 0,
        total_amount: this.amount,
        error: this.error !== '',
      };
      this.$emit('payment-updated', result);
    },
    async initialLoad() {
      this.paymentType = 'full';
      this.emitPaymentUpdate();
    }
  },

  async beforeUnmount() {
    await this.resetData();
  },
}
</script>

<template>

  <div class="d-flex flex-column flex-md-row align-items-center px-1">

    <div class="" style="">
      <div>
        <p class="mb-2 text-bold-700 m-0">Payment Type</p>
      </div>
      <!-- Radio Buttons -->
      <div class="d-flex mb-3 mb-md-0 pb-1">
        <!-- Quarter -->
        <div>
          <div class="custom-control-inline radio radio-warning radio-glow">
            <input
                v-model="paymentType"
                type="radio"
                id="paymentAmount25"
                name="paymentAmount"
                value="quarter"
                @change="calculateAmounts"
            />
            <label class="font-weight-bold" for="paymentAmount25">Quarter</label>
          </div>
          <div class="text-warning text-bold-600" v-if="paidAmountQuarter && showAmount">
            ${{ parseFloat(paidAmountQuarter / 100).toFixed(2) }}
          </div>
        </div>

        <!-- Half -->
        <div>
          <div class="custom-control-inline radio radio-dark radio-glow">
            <input
                v-model="paymentType"
                type="radio"
                id="paymentAmount50"
                name="paymentAmount"
                value="half"
                @change="calculateAmounts"
            />
            <label class="font-weight-bold" for="paymentAmount50">Half</label>
          </div>
          <div class="text-dark text-bold-600" v-if="paidAmountHalf && showAmount">${{
              parseFloat(paidAmountHalf / 100).toFixed(2)
            }}
          </div>
        </div>

        <!-- Full -->
        <div>
          <div class="custom-control-inline radio radio-success radio-glow">
            <input
                v-model="paymentType"
                type="radio"
                id="paymentAmount100"
                name="paymentAmount"
                value="full"
                @change="calculateAmounts"
            />
            <label class="font-weight-bold" for="paymentAmount100">Full</label>
          </div>
          <div class="text-success text-bold-600" v-if="paidAmountFull && showAmount">${{
              parseFloat(paidAmountFull / 100).toFixed(2)
            }}
          </div>
        </div>

        <!-- Other -->
        <div>
          <div class="custom-control-inline radio radio-danger radio-glow">
            <input
                v-model="paymentType"
                type="radio"
                id="paymentAmountOther"
                name="paymentAmount"
                value="custom"
                @change="calculateAmounts"
            />
            <label class="font-weight-bold" for="paymentAmountOther">Other</label>
          </div>
          <div></div>
        </div>
      </div>
    </div>

    <!-- Input Field (will move to next row on small screens) -->
    <div class=" mt-3 mt-md-1" v-if="paymentType === 'custom'">
      <div class="">
        <input
            v-model.number="paidAmountInput"
            @keypress="allowOnlyNumbersWithDecimal"
            placeholder="Enter an amount"
            type="text"
            class="form-control"
        />
      </div>
      <div class="text-left" style="height: 10px;">
        <p class="text-danger m-0 font-small-1">
          {{ error }}
        </p>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>