<template>
  <div class="modal fade" id="appointmentViewModal" tabindex="-1" role="dialog" aria-labelledby="appointmentViewModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Appointment Details ({{ appointmentDetails.preference }})
            <button v-if="$route.name === 'appOrderList'" class="btn btn-sm btn-danger"
                    @click="vieAppointmentFullDetailsHandler(appointmentDetails.reference )">view on appointment list
            </button>
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <h3 class="text-center pb-1"><span v-if="appointmentDetails.type === 'Home'"><i class="bx bx-home"
                                                                                            style="font-size: 1.5rem;"></i></span>
              <span v-else><i class="bx bxs-business" style="font-size: 1.5rem;"></i></span> <span
                  v-if="appointmentDetails.service.name">{{ appointmentDetails.service.name }}</span> <span
                  class="text-secondary text-light"
                  v-if="appointmentDetails.reference">({{ appointmentDetails.reference }})</span> <span></span> <span
                  class="text-success"
                  v-if="appointmentDetails.status === 'Delivered'">({{ appointmentDetails.status }})</span></h3>

          </div>
          <div>
            <div class="row">
              <div class="col-md-6 col-12 form-group">
                <div>
                  <h5>Customer details</h5>
                </div>
                <div>
                  <span class=""
                        v-if="appointmentDetails.customer.user.first_name  && appointmentDetails.customer.user.last_name"><i
                      class='bx bx-user' :style="{fontSize:'15px'}"></i> </span>
                  <span>{{ `${appointmentDetails.customer.user.first_name} ${appointmentDetails.customer.user.last_name}` }}</span>
                </div>
                <div>
                  <span class="" v-if="appointmentDetails.customer.user.email"><i class='bx bx-mail-send'
                                                                                  :style="{fontSize:'15px'}"></i></span>
                  <span>{{ appointmentDetails.customer.user.email }}</span>
                </div>
                <div>
                  <span class="" v-if="appointmentDetails.customer.user.phone_number"><i class='bx bx-phone'
                                                                                         :style="{fontSize:'15px'}"></i></span>
                  <span>{{ appointmentDetails.customer.user.phone_number }}</span>
                </div>
                <div>
                  <span class="" v-if="appointmentDetails.customer.address.id"><i class='bx bx-map'
                                                                                  :style="{fontSize:'15px'}"></i> </span>
                  <span>{{ buildAddress(appointmentDetails?.customer?.address) }}</span>
                </div>

              </div>
              <div class="col-md-4 offset-md-2 col-12  text-right" v-if="appointmentDetails.technician.id">
                <div>
                  <div>
                    <h5>Technician details</h5>
                  </div>
                  <div v-if="appointmentDetails.technician">
                    <span class=""
                          v-if="appointmentDetails.technician.firstName  && appointmentDetails.technician.lastName"><i
                        class='bx bx-user' :style="{fontSize:'15px'}"></i> </span>
                    <span>{{ `${appointmentDetails.technician.firstName} ${appointmentDetails.technician.lastName}` }}</span>
                  </div>
                  <div>
                    <span class="" v-if="appointmentDetails.technician.email"><i class='bx bx-mail-send'
                                                                                 :style="{fontSize:'15px'}"></i></span>
                    <span>{{ appointmentDetails.technician.email }}</span>
                  </div>
                  <div>
                    <span class="" v-if="appointmentDetails.technician.phoneNumber"><i class='bx bx-phone'
                                                                                       :style="{fontSize:'15px'}"></i></span>
                    <span>{{ appointmentDetails.technician.phoneNumber }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-12 form-group">
                <div>
                  <label>Service address : </label>
                </div>
                <div v-if="appointmentDetails.preference === 'On-Site'">
                  <span class="" v-if="appointmentDetails.serviceAddress.id"><i class='bx bx-map'
                                                                                :style="{fontSize:'15px'}"></i> </span>
                  <span> {{ buildAddress(appointmentDetails?.serviceAddress)}}</span>
                </div>
                <div v-else class="badge badge-pill"><span>{{ appointment.preference }}</span></div>

                <div class="mt-1">
                  <label>Billing address : </label>
                </div>

                <div>
                  <span class="" v-if="appointmentDetails.billingAddress.id"><i class='bx bx-map'
                                                                                :style="{fontSize:'15px'}"></i> </span>
                  <span> {{ buildAddress(appointmentDetails?.billingAddress)}}</span>
                </div>

              </div>
              <div class="col-md-4 offset-md-2 col-12 form-group text-right">
                <div>
                  <div class="d-flex justify-content-end">
                    <span class=""><i class='bx bxs-parking bg-warning rounded-sm text-white'
                                      :style="{fontSize:'28px',padding:'5px 5px'}"></i></span>
                    <div class="" :style="{paddingLeft:'5px'}">
                      <small class="py-0 my-0">PARKING</small>
                      <p class="mb-0">
                        <small>{{ appointmentDetails.parking ? appointmentDetails.parking : 'No' }}</small></p>
                    </div>
                  </div>
                  <div>
                    <div class=""><span
                        class="">Service Price(+) :</span>${{ appointmentDetails.appointmentTotalServicePrice }} <small
                        class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>
                  <div>
                    <div class=""><span class=""><i style="font-size:14px;cursor:pointer;"
                                                    @click="openDiscountDetailsModal('Surcharge')"
                                                    class="bx bx-show"></i>Surcharge(+) :</span>${{ appointmentDetails.appointmentTotalSurCharge }}
                      <small class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>
                  <div>
                    <div class=""><span class="">GST(+) :</span>${{ appointmentDetails.appointmentTotalGst }} <small
                        class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>

                  <div>
                    <div class=""><span class=""><i style="font-size:14px;cursor:pointer;"
                                                    @click="openDiscountDetailsModal('Discount')"
                                                    class="bx bx-show"></i> Discount(-) :</span>${{ appointmentDetails.appointmentTotalDiscount }}
                      <small class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>
                  <div>
                    <div class=""><span class="">Sub Total :</span> ${{ appointmentDetails.appointmentTotalCharge }}
                      <small class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>
                  <div>
                    <div class=""><span class="">Paid :</span>
                      ${{ parseFloat(parseFloat(appointmentDetails.appointmentTotalPaid) + parseFloat(appointmentDetails.appointmentTotalCardSurcharge)).toFixed(2) }}
                      <small class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>

                  <div v-if="parseFloat(appointmentDetails.appointmentTotalCardSurcharge) > 0">
                    <div class=""><span
                        class="">Card Charges: $</span>{{ appointmentDetails.appointmentTotalCardSurcharge }} <small
                        class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>
                  <div>
                    <div class=""><span
                        class="">Grand Total: $</span>{{ parseFloat(parseFloat(appointmentDetails.appointmentTotalCharge) + parseFloat(appointmentDetails.appointmentTotalCardSurcharge)).toFixed(2) }}
                      <small class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>
                  <div class=""
                       v-if="parseFloat(appointmentDetails.appointmentTotalCharge) > parseFloat(appointmentDetails.appointmentTotalPaid)">
                    <div class=""><span
                        class="">Due: $</span>{{ (parseFloat(parseFloat(appointmentDetails.appointmentTotalCharge) - parseFloat(appointmentDetails.appointmentTotalPaid)).toFixed(2)) }}
                      <small class="" style="font-size: 0.8rem;">AUD</small></div>
                  </div>

                  <div class="">
                    <span class="font-weight-bolder">Status:</span> <span
                      class="badge badge-secondary">{{ appointmentDetails.status }}</span>
                  </div>
                  <div class="">
                    <span class="font-weight-bolder">Payment Status:</span>

                    <span v-if="appointmentDetails.appointmentPaymentStatus === 'Paid'"
                          class="badge badge-success">{{ appointmentDetails.appointmentPaymentStatus }}</span>
                    <span v-if="appointmentDetails.appointmentPaymentStatus === 'Unpaid'"
                          class="badge badge-warning">{{ appointmentDetails.appointmentPaymentStatus }}</span>
                    <span v-if="appointmentDetails.appointmentPaymentStatus === 'Partially paid'"
                          class="badge badge-primary">{{ appointmentDetails.appointmentPaymentStatus }}</span>

                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-12  py-1 text-dark" :style="{backgroundColor:'#edf2f9'}">
                <h4 class="text-center mb-0 font-weight-bold" v-if=" appointmentDetails.date">{{
                    new Date(`${appointmentDetails.date}`).toLocaleDateString("en-AU", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  }}</h4>
                <p class="text-center mb-0"> {{ appointmentDetails.time }}</p>
              </div>
            </div>
            <div class="row pt-1">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-light">
                    <tr>
                      <th>SERVICE</th>
                      <th>RTL</th>
                      <th>Length</th>
                      <th>QTY</th>
                      <th>UNIT PRICE</th>
                      <th>PRICE</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="text-bold-500">{{ appointmentDetails.service.name }}</td>
                      <td>{{ appointmentDetails.requestedTimeLength }} hours</td>
                      <td>{{ appointmentDetails.length }}</td>
                      <td>{{ appointmentDetails.quantity }}</td>
                      <td class="text-bold-500">${{ parseFloat(appointmentDetails.unitPrice * 0.01).toFixed(2) }}</td>
                      <td class="text-bold-500">
                        ${{ parseFloat(appointmentDetails.appointmentTotalServicePrice).toFixed(2) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="p-2" v-if="appointmentDetails?.appointmentNotes?.length"
               :style="{backgroundColor:'rgb(255, 244, 163,0.4)'}">

            <p :style="{color:'#000'}" class="mb-0">
              <span v-if="appointmentDetails.appointmentNotes?.[0]"
                    class="font-weight-bolder">Notes: </span>{{ appointmentDetails.appointmentNotes?.[0]?.description }}

            </p>
          </div>
          <div class="py-2">
            <h4 class="text-secondary" v-if="appointmentDetails.appointmentServiceAgreementConsents.length > 0">Service
              agreement Consents</h4>
            <div v-for="(appointmentConsent,index) in appointmentDetails.appointmentServiceAgreementConsents"
                 :key="index">
              <p class="mb-0"><strong>Status: </strong> {{ appointmentConsent.status }} </p>
              <p class="mb-0"><strong>Issue Notes: </strong> {{ appointmentConsent.remark }}</p>
              <p class="mb-0"><strong>Signature: </strong>
                <img :src="appointmentConsent.signature" alt="customer-signature" style="width:100px;">
              </p>
              <hr>
            </div>
          </div>
          <div class="py-2">
            <h4 class="text-secondary" v-if="appointmentDetails.appointmentCustomerConsents.length > 0">Customer
              Consents</h4>
            <div v-for="(appointmentConsent,index) in appointmentDetails.appointmentCustomerConsents" :key="index">
              <p class="mb-0"><strong>Status: </strong> {{ appointmentConsent.status }} </p>
              <p class="mb-0"><strong>Issue Notes: </strong> {{ appointmentConsent.remark }}</p>
              <p class="mb-0"><strong>Signature: </strong>
                <img :src="appointmentConsent.signature" alt="customer-signature" style="width:100px;">
              </p>
              <hr>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 py-1">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>

        </div>
        <div class="" data-toggle="modal" data-target="#appointmentChargesDetailsModal"></div>
        <AppointmentChargesDetailsModal modalName="appointmentChargesDetailsModal" :modal-title="modalTitle"
                                        :appointmentCharges="currentAppointmentCharges"/>
      </div>
    </div>

  </div>
</template>

<script>
import AppointmentChargesDetailsModal
  from "@/views/backEnd/appointments/appointmentList/includes/AppointmentChargesDetailsModal";
import {mapGetters} from "vuex";
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";

export default {
  name: "AppointmentViewModal",
  mixins: [AddressMixin],
  props: ['appointment'],
  components: {
    AppointmentChargesDetailsModal
  },
  data() {
    return {
      modalTitle: '',
      currentAppointmentCharges: [],
      appointmentDetails: {
        requestedTimeLength: "",
        id: '',
        reference: "",
        quantity: '',
        type: "",
        status: "",
        platform: "",
        parking: "",
        preference: "",
        date: "",
        time: "",
        length: null,
        service: {
          id: '',
          name: "",
          code: "",
          description: "",
          home_price: '',
          business_price: '',
          commission: "",
          status: "",
          // image:"http:\/\/localhost:8000\/default\/images\/services\/no-image.png",
          // thumbnail:"http:\/\/localhost:8000\/default\/images\/services\/no-image.png"
        },
        business: null,
        order: null,
        customer: {
          id: 201,
          type: "",
          status: "",
          referral_code: "",
          newsletter_subscription: "",
          user: {
            id: '',
            first_name: "",
            last_name: "",
            email: null,
            phone_number: "",

            // avatar:"http:\/\/localhost:8000\/default\/images\/users\/avatar.png"
          },
          address: {
            id: '',
            street: "",
            suburb: "",
            state: "",
            post_code: "",
            country: ""
          }
        },
        technician: {
          id: "",
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        },

        billingAddress: {
          id: '',
          street: "",
          suburb: "",
          state: "",
          post_code: "",
          country: ""
        },

        serviceAddress: {
          id: '',
          street: "",
          suburb: "",
          state: "",
          post_code: "",
          country: ""
        },
        appointmentCharges: [],
        appointmentPayments: [],

        appointmentTotalCharge: 0,
        appointmentTotalPaid: 0,
        appointmentTotalSurCharge: 0,
        appointmentTotalServicePrice: 0,
        appointmentTotalDiscount: 0,
        appointmentTotalGst: 0,

        appointmentPaymentStatus: '',
        appointmentNotes: [],
        appointmentCustomerConsents: [],
        appointmentServiceAgreementConsents: [],
        appointmentCreator: {
          id: '',
          panel: ""
        },
        appointmentConsent: null,
        unitPrice: '',
        appointmentTotalCardSurcharge: 0,

      }
    }
  },
  watch: {
    appointment(currentAppointment) {
      this.appointmentDetails.id = currentAppointment.id ? currentAppointment.id : '';
      this.appointmentDetails.time = currentAppointment.time ? currentAppointment.time : '';
      this.appointmentDetails.preference = currentAppointment.preference ? currentAppointment.preference : '';
      this.appointmentDetails.quantity = currentAppointment.quantity ? currentAppointment.quantity : '';
      this.appointmentDetails.unitPrice = currentAppointment.unit_price ? currentAppointment.unit_price : '';
      this.appointmentDetails.service.name = currentAppointment.service && currentAppointment.service.name ? currentAppointment.service.name : '';
      this.appointmentDetails.service.home_price = currentAppointment.service && currentAppointment.service.home_price ? currentAppointment.service.home_price : '';
      this.appointmentDetails.service.business_price = currentAppointment.service && currentAppointment.service.business_price ? currentAppointment.service.business_price : '';
      this.appointmentDetails.type = currentAppointment.type ? currentAppointment.type : '';
      this.appointmentDetails.reference = currentAppointment.reference ? currentAppointment.reference : '';
      this.appointmentDetails.date = currentAppointment.date ? currentAppointment.date : '';
      this.appointmentDetails.parking = currentAppointment.parking ? currentAppointment.parking : '';
      this.appointmentDetails.appointmentNotes = currentAppointment.appointmentNotes ? currentAppointment.appointmentNotes : '';
      this.appointmentDetails.status = currentAppointment.status ? currentAppointment.status : '';

      this.appointmentDetails.customer.user.first_name = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.first_name ? currentAppointment.customer.user.first_name : '';
      this.appointmentDetails.customer.user.last_name = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.last_name ? currentAppointment.customer.user.last_name : '';
      this.appointmentDetails.customer.user.email = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.email ? currentAppointment.customer.user.email : '';
      this.appointmentDetails.customer.user.phone_number = currentAppointment.customer && currentAppointment.customer.user && currentAppointment.customer.user.phone_number ? currentAppointment.customer.user.phone_number : '';

      this.appointmentDetails.customer.address.id = (currentAppointment.address && currentAppointment.address.id) ? currentAppointment.address.id : '';
      this.appointmentDetails.customer.address.subpremise = currentAppointment?.address?.subpremise ?? '';
      this.appointmentDetails.customer.address.street = (currentAppointment.address && currentAppointment.address.street) ? currentAppointment.address.street : '';
      this.appointmentDetails.customer.address.suburb = currentAppointment.address && currentAppointment.address.suburb ? currentAppointment.address.suburb : '';
      this.appointmentDetails.customer.address.state = currentAppointment.address && currentAppointment.address.state ? currentAppointment.address.state : '';
      this.appointmentDetails.customer.address.country = currentAppointment.address && currentAppointment.address.country ? currentAppointment.address.country : '';

      this.appointmentDetails.billingAddress.id = currentAppointment.billingAddress && currentAppointment.billingAddress.id ? currentAppointment.billingAddress.id : '';
      this.appointmentDetails.billingAddress.subpremise = currentAppointment?.billingAddress?.subpremise ?? '';
      this.appointmentDetails.billingAddress.street = currentAppointment.billingAddress && currentAppointment.billingAddress.street ? currentAppointment.billingAddress.street : '';
      this.appointmentDetails.billingAddress.suburb = currentAppointment.billingAddress && currentAppointment.billingAddress.suburb ? currentAppointment.billingAddress.suburb : '';
      this.appointmentDetails.billingAddress.state = currentAppointment.billingAddress && currentAppointment.billingAddress.state ? currentAppointment.billingAddress.state : '';
      this.appointmentDetails.billingAddress.post_code = currentAppointment.billingAddress && currentAppointment.billingAddress.post_code ? currentAppointment.billingAddress.post_code : '';
      this.appointmentDetails.billingAddress.country = currentAppointment.billingAddress && currentAppointment.billingAddress.country ? currentAppointment.billingAddress.country : '';

      this.appointmentDetails.serviceAddress.id = currentAppointment.address && currentAppointment.address.id ? currentAppointment.address.id : '';
      this.appointmentDetails.serviceAddress.subpremise = currentAppointment?.address?.subpremise ?? '';
      this.appointmentDetails.serviceAddress.street = currentAppointment.address && currentAppointment.address.street ? currentAppointment.address.street : '';
      this.appointmentDetails.serviceAddress.suburb = currentAppointment.address && currentAppointment.address.suburb ? currentAppointment.address.suburb : '';
      this.appointmentDetails.serviceAddress.state = currentAppointment.address && currentAppointment.address.state ? currentAppointment.address.state : '';
      this.appointmentDetails.serviceAddress.post_code = currentAppointment.address && currentAppointment.address.post_code ? currentAppointment.address.post_code : '';
      this.appointmentDetails.serviceAddress.country = currentAppointment.address && currentAppointment.address.country ? currentAppointment.address.country : '';

      this.appointmentDetails.technician.id = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.id ? currentAppointment.technicianAppointment.technician.id : '';
      this.appointmentDetails.technician.firstName = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.user && currentAppointment.technicianAppointment.technician.user.first_name ? currentAppointment.technicianAppointment.technician.user.first_name : '';
      this.appointmentDetails.technician.lastName = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.user && currentAppointment.technicianAppointment.technician.user.last_name ? currentAppointment.technicianAppointment.technician.user.last_name : '';
      this.appointmentDetails.technician.email = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.user && currentAppointment.technicianAppointment.technician.user.email ? currentAppointment.technicianAppointment.technician.user.email : '';
      this.appointmentDetails.technician.phoneNumber = currentAppointment.technicianAppointment && currentAppointment.technicianAppointment.technician && currentAppointment.technicianAppointment.technician.user && currentAppointment.technicianAppointment.technician.user.phone_number ? currentAppointment.technicianAppointment.technician.user.phone_number : '';

      this.appointmentDetails.appointmentCharges = (currentAppointment.appointmentCharges) ? currentAppointment.appointmentCharges : [];
      this.appointmentDetails.appointmentPayments = (currentAppointment.appointmentPayments) ? currentAppointment.appointmentPayments : [];
      // this.appointmentDetails.appointmentConsents = (currentAppointment.appointmentConsents) ? currentAppointment.appointmentConsents : [];
      this.appointmentDetails.appointmentServiceAgreementConsents = currentAppointment?.appointmentConsents?.filter((singleConsent) => singleConsent.type === 'Service Agreement Consent') ?? [];
      this.appointmentDetails.appointmentCustomerConsents = currentAppointment?.appointmentConsents?.filter((singleConsent) => singleConsent.type === 'Appointment Closing Consent') ?? [];

      let charge = 0;

      let appointmentTotalServicePrice = 0;
      let appointmentTotalSurCharge = 0;
      let appointmentTotalGst = 0;
      let appointmentTotalDiscount = 0;

      this.appointmentDetails.appointmentCharges.map((item) => {

        if (item.type === "GST") {
          charge = charge + item.amount;
          appointmentTotalGst = appointmentTotalGst + item.amount;

        } else if (item.type === "Service Price") {

          charge = charge + item.amount;
          appointmentTotalServicePrice = appointmentTotalServicePrice + item.amount;

        } else if (item.type === "Surcharge") {
          charge = charge + item.amount;
          appointmentTotalSurCharge = appointmentTotalSurCharge + item.amount;

        } else if (item.type === "Discount") {
          charge = charge - item.amount;
          appointmentTotalDiscount = appointmentTotalDiscount + item.amount;
        }

      });

      charge = parseFloat(charge / 100).toFixed(2);

      appointmentTotalServicePrice = parseFloat(appointmentTotalServicePrice / 100).toFixed(2);
      appointmentTotalSurCharge = parseFloat(appointmentTotalSurCharge / 100).toFixed(2);
      appointmentTotalGst = parseFloat(appointmentTotalGst / 100).toFixed(2);
      appointmentTotalDiscount = parseFloat(appointmentTotalDiscount / 100).toFixed(2);

      this.appointmentDetails.appointmentTotalCharge = charge;

      this.appointmentDetails.appointmentTotalSurCharge = appointmentTotalSurCharge;
      this.appointmentDetails.appointmentTotalGst = appointmentTotalGst;
      this.appointmentDetails.appointmentTotalDiscount = appointmentTotalDiscount;
      this.appointmentDetails.appointmentTotalServicePrice = appointmentTotalServicePrice;

      let paidAmount = 0;

      this.appointmentDetails.appointmentPayments.map((item) => {
        paidAmount = paidAmount + item.payment.total_transaction;
      });

      paidAmount = parseFloat(paidAmount / 100).toFixed(2);
      this.appointmentDetails.appointmentTotalPaid = paidAmount;
      if (parseFloat(paidAmount) === parseFloat(charge)) {
        this.appointmentDetails.appointmentPaymentStatus = "Paid";
      } else if (parseFloat(paidAmount) <= 0) {
        this.appointmentDetails.appointmentPaymentStatus = "Unpaid";
      } else {
        this.appointmentDetails.appointmentPaymentStatus = "Partially paid";
      }
      this.appointmentDetails.length = currentAppointment?.length ?? null;
      this.appointmentDetails.requestedTimeLength = currentAppointment?.requested_time_interval ?? null;
      let cardSurcharge = currentAppointment?.appointmentPayments?.reduce(function (acc, cur) {
        return cur?.payment?.type === 'Card' ? (acc + cur?.payment?.cardPayment?.card_surcharge ?? 0) :
            (cur?.payment?.type === 'Afterpay' ? (acc + cur?.payment?.afterpayPayment?.card_surcharge ?? 0) : acc)
      }, 0) ?? 0;
      this.appointmentDetails.appointmentTotalCardSurcharge = parseFloat(cardSurcharge / 100).toFixed(2);

    },

  },
  computed: {
    ...mapGetters({
      settingAppointmentServiceDuration: 'appSettings/settingAppointmentServiceDuration',

    }),

  },
  methods: {
    openDiscountDetailsModal(discountType) {

      this.currentAppointmentCharges = {};
      this.modalTitle = "";
      this.modalTitle = discountType;
      this.currentAppointmentCharges = this.appointmentDetails.appointmentCharges.filter(charge => charge.type == discountType);
      document.querySelector(`[data-target='#appointmentChargesDetailsModal']`).click()
    },
    async vieAppointmentFullDetailsHandler(appointmentReference) {
      document.querySelector('[data-target="#appointmentViewModal"]').click();
      await this.$router.push({
        name: 'appAppointmentList',
        params: {
          appointmentReference: appointmentReference,

        }
      });

    },
  },

}
</script>

<style>

</style>
